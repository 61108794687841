<template>
    <section class="xbts-lp-create">
        <div v-show="step === 1">
            <div class="my-1">
                <b-form-group
                        :label="$t('assets.asset') + ' A'"
                        label-for="input_a">
                    <b-input-group>
                        <b-form-input
                                @input="changeAssetInput"
                                id="input_a"
                                placeholder="ex. XBTSX.BTC"
                                type="text" v-model="model.a"></b-form-input>
                    </b-input-group>
                </b-form-group>
            </div>

            <div class="my-1">
                <b-form-group
                        :label="$t('assets.asset') + ' B'"
                        label-for="input_b">
                    <b-input-group>
                        <b-form-input
                                @input="changeAssetInput"
                                id="input_b"
                                placeholder="ex. XBTSX.ETH"
                                type="text" v-model="model.b"></b-form-input>
                    </b-input-group>
                </b-form-group>
            </div>

            <div class="my-1">
                <b-form-group
                        :label="$t('pool.share_asset')"
                        label-for="input_share">
                    <b-input-group>
                        <b-form-input
                                @input="changeAssetInput"
                                id="input_share"
                                placeholder="ex. SMARTHOLDEM"
                                type="text" v-model="model.share"></b-form-input>
                    </b-input-group>
                </b-form-group>
            </div>

            <div class="my-1">
                <b-form-group
                        label="Taker Fee"
                        label-for="input_taker">
                    <b-input-group>
                        <b-form-input
                                id="input_taker"
                                placeholder=""

                                type="text" v-model="model.takerFee"></b-form-input>
                    </b-input-group>
                </b-form-group>
            </div>

            <div class="my-1">
                <b-form-group
                        label="Withdraw Fee"
                        label-for="input_withdraw">
                    <b-input-group>
                        <b-form-input
                                id="input_withdraw"
                                placeholder=""

                                type="text" v-model="model.withdrawFee"></b-form-input>
                    </b-input-group>
                </b-form-group>
            </div>

            <p>
                {{$t('wallet.fee_network')}} {{chainFee}} BTS
            </p>

            <button :disabled="chainFee > balanceBts" @click="createPool" class="btn btn-inverse-primary btn-fw w-100 mt-4" type="button">
                {{$t('app.confirm')}}
            </button>

            <b-alert v-show="chainFee > balanceBts && this.$root.account" variant="warning" show class="mt-2 bg-gradient- bg-warning text-dark small">
                No BTS balance for pay fees
            </b-alert>
        </div>

        <div v-show="step === 2">
            {{raw}}
            <hr/>
            {{result}}
        </div>



    </section>
</template>

<script>
export default {
    name: "LpCreate",
    data() {
        return {
            step: 1,
            result: null,
            model: {
                a: "",
                b: "",
                share: "",
                takerFee: 0.30,
                withdrawFee: 0.20,
            },
            raw: null,
            chainFee: 0,
        }
    },
    computed: {
        balanceBts() {
            return this.$store.getters['accounts/portfolio']['1.3.0'].amount / 10 ** 5 || 0;
        },
    },
    async created() {
        this.chainFee = this.$store.getters['app/fees']['59'].fee / 10 ** 5;
    },
    methods: {
        async changeAssetInput() {
            this.model.a ? this.model.a = this.model.a.toUpperCase() : "";
            this.model.b ? this.model.b = this.model.b.toUpperCase() : "";
            this.model.share ? this.model.share = this.model.share.toUpperCase() : "";
        },
        async createPool() {
            this.step = 2;
            this.result = null;
            const assetA = await this.$store.dispatch('assets/getAsset', this.model.a);
            const assetB = await this.$store.dispatch('assets/getAsset', this.model.b);
            const assetShare = await this.$store.dispatch('assets/getAsset', this.model.share);
            // op 59
            this.raw = {
                "fee": {
                    "amount": this.$store.getters['app/fees']['59'].fee,
                    "asset_id": "1.3.0"
                },
                "account": this.$root.account.account.id,
                "asset_a": assetA.id,
                "asset_b": assetB.id,
                "share_asset": assetShare.id,
                "taker_fee_percent": this.model.takerFee * 100,
                "withdrawal_fee_percent": this.model.withdrawFee * 100,
                "extensions": []
            };



            this.result = await this.$store.dispatch('lp/LpCreate', {
                account: this.$root.account,
                operation: this.raw,
            });


            if (this.raw) {
                this.$snotify.success('Liquidity Created!', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                });

                let _self = this;
                setTimeout(async () => {
                    this.raw = null;
                    this.result = null;
                    this.step = 1;
                    await _self.routeTo('/pool/' + this.model.share);
                }, 5000);
            }




        },

    }
}
</script>

<style scoped>

</style>
