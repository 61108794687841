<script src="../../store/modules/assets.js"></script>
<template>
  <section v-if="info" class="xbts-lp-info">
    <div>
      <table class="table table-striped table-borderless table-responsive-sm">
        <tbody>
        <tr>
          <td class="text-capitalize">{{ $t('pool.pool') }}</td>
          <td>{{ info.pool.id }} <span v-if="assetLp" :title="$t('pool.apy_tip')" v-b-tooltip.html.bot
                                       class="float-right badge badge-gradient-success text-dark font-weight-bold">{{ (assetLp.APY).toFixed(2) }}% <i class="icon-question"></i></span>
          </td>
        </tr>
        <tr class="border-top border-info">
          <td>
            <span class="float-left text-capitalize">{{ $t('market.pair') }}</span>
          </td>
          <td>
            <img v-if="allAssets[info.asset_a.symbol]" :src="'images/assets/' + info.asset_a.symbol.toLowerCase() + '.png'" class="mr-1 icon-18"/>
            <vue-letter-avatar v-else :name="assetToSymbol(info.asset_a.symbol)" size='18' :rounded=true class="mr-1"/>


            <span v-html="formatAssetPrefix(info.asset_a.symbol).html"></span> <span
              class="ml-1 mr-1 text-white">+</span>
            <span v-if="allAssets[info.asset_b.symbol]"><img
                :src="'images/assets/' + info.asset_b.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/></span>
            <span v-if="!allAssets[info.asset_b.symbol]"><img :src="'images/assets/coin.png'"
                                                              class="mr-2 icon-18"/></span>
            <span v-html="formatAssetPrefix(info.asset_b.symbol).html"></span>
          </td>
        </tr>
        <tr>
          <td class="text-capitalize">{{ $t('market.fees') }} {{ $t('market.taker') }}/{{ $t('market.withdraw') }}</td>
          <td>{{ info.pool.taker_fee_percent / 100 }}% / {{ info.pool.withdrawal_fee_percent / 100 }}%</td>
        </tr>
        <tr>
          <td class="text-capitalize">{{ $t('pool.share_asset') }}</td>
          <td>
            <router-link v-if="assetLp" :to="'/asset/'+info.share.symbol">
              <span v-html="formatAssetPrefix(info.share.symbol).html"></span>
              <vue-letter-avatar :name="assetToSymbol(assetLp.SHARE.asset.symbol)" size='18' :rounded=true class="ml-1"/>
            </router-link>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('assets.current_supply') }} LP
          </td>
          <td>
            <div v-if="assetLp">
            <span v-if="assetLp.SHARE">
              {{ numTransform(assetLp.SHARE.supply) }}
            </span>
            <span class="ml-1 text-info" v-show="assetLp.SHARE.amount * prices[currency].price > 9999">[{{ currSymbols[currency] }}{{numTransform(assetLp.SHARE.amount * prices[currency].price)}}]</span>
            <span class="ml-1 text-info" v-show="assetLp.SHARE.amount > 0 && assetLp.SHARE.amount * prices[currency].price < 10000">[{{ currSymbols[currency] }}{{formatNum(assetLp.SHARE.amount * prices[currency].price, '# ##0.')}}]</span>
            </div>
          </td>
        </tr>
        <tr class="border-top border-info">
          <td colspan="2" class="text-center text-white text-capitalize">{{ $t('pool.u_liquidity') }}</td>
        </tr>
        <tr>
          <td>
            <img :src="'images/assets/' + info.asset_a.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/>
            <router-link :to="'/asset/'+info.asset_a.symbol">
              <span v-html="formatAssetPrefix(info.asset_a.symbol.replace('XBTSX.','')).html"></span>
            </router-link>

            <span class="ml-2 text-white">
              {{numTransform(info.asset_a.balance)}}
            </span>
          </td>

          <td class="text-right">
            <span v-if="allAssets[info.asset_b.symbol]"><img
                :src="'images/assets/' + info.asset_b.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/></span>
            <span v-if="!allAssets[info.asset_b.symbol]"><img :src="'images/assets/coin.png'" class="mr-2 icon-18"/></span>
            <router-link :to="'/asset/'+info.asset_b.symbol">
              <span v-html="formatAssetPrefix(info.asset_b.symbol.replace('XBTSX.','')).html"></span>
            </router-link>


            <span class="ml-2 text-white">
              {{numTransform(info.asset_b.balance)}}
            </span>


          </td>

        </tr>

        <tr>
          <td colspan="2" class="text-center text-white text-capitalize">{{ $t('pool.vol24') }}</td>
        </tr>
        <tr>
          <td>
            <img :src="'images/assets/' + info.asset_a.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/>
            <span v-html="formatAssetPrefix(info.asset_a.symbol.replace('XBTSX.','')).html"></span>
            <span v-if="assetLp" class="ml-3">{{numTransform(assetLp.A.vol24)}}</span>
          </td>
          <td class="text-right">
            <img :src="'images/assets/' + info.asset_b.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/>
            <span v-html="formatAssetPrefix(info.asset_b.symbol.replace('XBTSX.','')).html"></span>
            <span v-if="assetLp" class="ml-3">{{numTransform(assetLp.B.vol24)}}</span>
          </td>
        </tr>

        <tr>
          <td class="text-center text-white" colspan="2">{{ $t('market.price') }}</td>
        </tr>
        <tr class="border-bottom border-info">
          <td class="w-50">
            <img :src="'images/assets/' + info.asset_a.symbol.toLowerCase() + '.png'" class="mr-1 icon-18"/> <span class="text-white">1</span>
            &nbsp;{{info.asset_a.symbol.replace('XBTSX.', '')}} =
            <span class="text-white" v-if="info.asset_b.balance / info.asset_a.balance > 1">
              <animated-number
                  :value="(info.asset_b.balance / info.asset_a.balance).toFixed(info.asset_b.precision)"
                  :formatValue="formatToPriceB"
                  :duration="400"
              />
          </span>
            <span class="text-white" v-if="info.asset_b.balance / info.asset_a.balance < 1">
              {{
                (info.asset_b.balance / info.asset_a.balance).toFixed(info.asset_b.precision)
              }}
          </span>
            &nbsp;{{info.asset_b.symbol.replace('XBTSX.', '')}}
          </td>
          <td class="w-50 text-right">
            <img :src="'images/assets/' + info.asset_b.symbol.toLowerCase() + '.png'" class="mr-1 icon-18"/> <span class="text-white">1</span>
            &nbsp;{{info.asset_b.symbol.replace('XBTSX.', '')}} =
            <span class="text-white" v-if="info.asset_a.balance / info.asset_b.balance > 1">
            <animated-number
                :value="(info.asset_a.balance / info.asset_b.balance)"
                :formatValue="formatToPrice"
                :duration="500"
            />
          </span>
            <span class="text-white" v-if="info.asset_a.balance / info.asset_b.balance < 1">
              <span v-if="(info.asset_a.balance / info.asset_b.balance).toFixed(info.asset_a.precision) > 0">{{(info.asset_a.balance / info.asset_b.balance).toFixed(info.asset_a.precision)}}</span>
              <span v-else class="small">{{(info.asset_a.balance / info.asset_b.balance).toFixed(12)}}</span>
          </span>&nbsp;{{info.asset_a.symbol.replace('XBTSX.', '')}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="balanceLp && info">
      <table class="table table-borderless tb-assets mt-2" v-if="balanceLp.amount > 0">
        <tr @click="unStake(balanceLp.symbol)">
          <td>
                                <span class="symbol-a">
                                  <span v-if="allAssets[balanceLp.pool.A.symbol]"><img
                                      :src="'images/assets/' + balanceLp.pool.A.symbol.toLowerCase() + '.png'"/></span>
                                  <span v-if="!allAssets[balanceLp.pool.A.symbol]"><img
                                      :src="'images/assets/coin.png'"/></span>
                                </span>
            <span class="symbol-b">
                                  <span v-if="allAssets[balanceLp.pool.B.symbol]"><img
                                      :src="'images/assets/' + balanceLp.pool.B.symbol.toLowerCase() + '.png'"/></span>
                                  <span v-if="!allAssets[balanceLp.pool.B.symbol]"><img
                                      :src="'images/assets/coin.png'"/></span>
                                </span>
            <span>{{ balanceLp.pool.A.symbol }}/{{ balanceLp.pool.B.symbol }}</span>
            <br>
          </td>
          <td>
                        <span class="text-success">
                            {{ formatBalance(balanceLp.amount / 10 ** balanceLp.precision, balanceLp.precision) }}
                        </span>
            <br/>
            <span v-if="assetLp"
                class="small">{{ ((balanceLp.amount / 10 ** balanceLp.precision) / assetLp.SHARE.supply * 100).toFixed(4) }}%</span>
          </td>
          <td v-show="amountMy > 0" class="text-right">
                        <span v-if="amountMy > 0" class="text-info">
                          <animated-number
                              :value="amountMy"
                              :formatValue="formatToPriceFiat"
                              :duration="400"
                          />
                            <!--{{ formatNum(amountMy, '# ##0.') }}-->
                          &nbsp;{{ currSymbols[currency] }}
                        </span>
            <br/>
            <span class="mt-2 small">
                            {{ $t('pool.apx_cost') }}
                        </span>
          </td>
        </tr>
      </table>
    </div>
    <p class="small p-2">{{
        $t('pool.info1', {
          fee_trade: info.pool.taker_fee_percent / 100,
          withdraw_percent: info.pool.withdrawal_fee_percent / 100
        })
      }}</p>
  </section>
  <div class="placeholder text-center" style="font-family: electrolize; font-size: 20pt; color: #ffffff;" v-else>
    {{ $t('app.loading') }}...
  </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {currSymbols, xbtsAssets, nativeAssets, xbtsFiat, otherAssets} from "@/config";
import AnimatedNumber from "animated-number-vue";

export default {
  name: "LpInfo",
  components: {
    AnimatedNumber
  },
  data() {
    return {
      info: null,
      assetLp: null,
      currSymbols: currSymbols,
      amountTotal: 0,
      amountMy: 0,
      allAssets: {},
    }
  },

  methods: {
    formatToPriceFiat(value) {
      return this.formatNum(value, '# ##0.');
    },
    formatToPriceB(value) {
      return `${value.toFixed(this.info.asset_b.precision)}`;
    },
    formatToPrice(value) {
      return `${value.toFixed(this.info.asset_a.precision)}`;
    },
    calcTotalAmount() {
      if (this.$root.account && this.assetLp && this.balanceLp) {
        const percentMy = (this.balanceLp.amount / 10 ** this.balanceLp.precision) / this.assetLp.SHARE.supply * 100;
        this.amountMy = (this.assetLp.SHARE.amount * this.prices[this.currency].price) / 100 * percentMy;
      }
    },
    async unStake() {
      await eventBus.emit('pool:tab', 'unstake');
      await eventBus.emit('lp:load:unstake', this.info.share.symbol);
    },
  },
  computed: {
    balanceLp() {
      if (this.info && this.$root.account && this.info.share.id) {
        return this.$store.getters['accounts/balancesLp'][this.info.share.id];
      } else {
        return false;
      }
    },
    prices() {
      return this.$store.getters['xbts/featuredPrice']['bts'];
    },
    currency() {
      return this.$store.getters['xbts/currency'];
    },
  },
  async created() {
    eventBus.on('pool:info:load', async (data) => {
      if (data.share) {
        this.info = data;
        this.assetLp = await this.$store.dispatch('lp/LpSingle', this.info.share.symbol);
        await this.calcTotalAmount();
      }
    });

    /*
    const assets = xbtsAssets.concat(nativeAssets, xbtsFiat, otherAssets);
    for (let i = 0; i < assets.length; i++) {
      this.allAssets[assets[i]] = true;
    }
     */

    this.allAssets = await this.knownAssets();
  }
}
</script>

<style>
.pair-icons {
  position: relative;
  width: 50px;
}

.asset-a-icon {
  z-index: 30;
  left: 50px;
  position: absolute;
}

.asset-b-icon {
  z-index: 20;
  position: absolute;
}
</style>
