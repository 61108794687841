<template>
  <section v-if="balance && $root.account" class="xbts-lp-unstake">
    <span v-show="!balance">No My Active Pools</span>

    <div v-show="step === 1">
      <table v-if="balance.symbol" class="table table-borderless table-striped tb-assets">
        <tbody>
        <tr>
          <td>{{ $t('assets.asset') }}</td>
          <td>{{ balance.symbol }}</td>
        </tr>
        <tr>
          <td>{{ $t('wallet.available') }}</td>
          <td><span class="text-info cursor-pointer" title="Set Max"
                    @click="setMax">{{ (balance.amount / 10 ** balance.precision).toFixed(balance.precision) }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b-form-input @input="enterAmountUnStake()" :placeholder="$t('pool.enter_amount')"
                          type="text"
                          v-model="unStake.amount"></b-form-input>
          </td>
          <td>{{ balance.symbol }}</td>
        </tr>
        <tr>
          <td colspan="2" class="text-center">
            {{ $t('pool.estimatedReceived') }}
          </td>
        </tr>
        <tr>
          <td>
                    <span>
                        <img v-if="pool.pool.A.symbol.split('.')[1]"
                             :src="'images/assets/' + pool.pool.A.symbol.split('.')[1].toLowerCase() + '.png'"/>
                        <img v-if="!pool.pool.A.symbol.split('.')[1]"
                             :src="'images/assets/' + pool.pool.A.symbol.split('.')[0].toLowerCase() + '.png'"/>
                    </span>
            {{ unStake.A }}
          </td>
          <td>{{ pool.pool.A.symbol }}</td>
        </tr>
        <tr>
          <td>
                    <span>
                        <img v-if="pool.pool.B.symbol.split('.')[1]"
                             :src="'images/assets/' + pool.pool.B.symbol.split('.')[1].toLowerCase() + '.png'"/>
                        <img v-if="!pool.pool.B.symbol.split('.')[1]"
                             :src="'images/assets/' + pool.pool.B.symbol.split('.')[0].toLowerCase() + '.png'"/>
                    </span>
            {{ unStake.B }}
          </td>
          <td>{{ pool.pool.B.symbol }}</td>
        </tr>
        <tr>
          <td>{{ $t('market.fee') }}</td>
          <td>{{ balance.pool.withdrawal_fee_percent / 100 }}%</td>
        </tr>
        <tr>
          <td>{{ $t('wallet.fee_network') }}</td>
          <td>{{ chainFee }} BTS</td>
        </tr>
        </tbody>
      </table>

      <div v-if="this.$root.account">
        <b-alert v-show="chainFee > balanceBts" variant="warning" show
                 class="mt-2 bg-gradient- bg-warning text-dark small">
          {{ $t('wallet.no_fees') }}
        </b-alert>
      </div>

      <div class="row">
        <div class="col-md-6">
          <button @click="confirmUnStake" :disabled="!unStake.amount || chainFee > balanceBts"
                  class="mt-3 btn btn-inverse-success btn-fw w-100" type="button">{{ $t('wallet.withdraw') }}
          </button>
        </div>
      </div>
    </div>

    <div v-show="step === 2">

      <div class="row" v-show="!result">
        <div class="ml-auto mr-auto">
          <intersecting-circles-spinner
              :animation-duration="1200"
              :size="70"
              color="#f9a901"
          />
        </div>
      </div>

      <button @click="step = 1"
              class="mt-3 btn btn-inverse-warning btn-fw w-100" type="button">{{ $t('app.continue') }}
      </button>
    </div>
  </section>
  <div class="placeholder" style="font-family: electrolize; font-size: 50pt; color: #ffffff;" v-else>
    <div class="ml-auto mr-auto">
      <intersecting-circles-spinner
          :animation-duration="1200"
          :size="70"
          color="#f9a901"
      />
    </div>
  </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {IntersectingCirclesSpinner} from 'epic-spinners'

export default {
  name: "LpUnstake",
  components: {
    IntersectingCirclesSpinner,
  },
  data() {
    return {
      chainFee: 0,
      step: 1,
      pool: null,
      balance: null,
      LpSymbol: '',
      unStake: {
        amount: null,
        A: 0,
        B: 0,
      },
      dynamic: null,
      raw: {},
      result: null,
      timerUnstake: null,
    }
  },
  methods: {
    async LpTabMy() {
      this.step = 1;
      await eventBus.emit('lp:load:my', this.pool.share.symbol);
    },
    async lpUpdate() {
      await eventBus.emit('lp:update', this.pool.share.symbol);
    },
    async confirmUnStake() {
      this.step = 2;
      this.result = null;
      // op 62
      this.raw = {
        "fee": {
          "amount": this.$store.getters['app/fees']['62'].fee,
          "asset_id": "1.3.0"
        },
        "account": this.$root.account.account.id,
        "pool": this.pool.pool.id,
        "share_amount": {
          "amount": this.unStake.amount * 10 ** this.pool.share.precision,
          "asset_id": this.pool.share.id
        },
        "extensions": []
      };

      this.result = await this.$store.dispatch('lp/LpUnStake', {
        account: this.$root.account,
        operation: this.raw,
      });

      if (this.result) {

      }

      setTimeout(async () => {

      }, 3000);
    },
    async setMax() {
      this.unStake.amount = this.balance.amount / 10 ** this.balance.precision;
      await this.enterAmountUnStake();
    },
    async enterAmountUnStake() {
      if (this.unStake.amount) {
        clearTimeout(this.timerUnstake);
        this.timerUnstake = setTimeout(async () => {
          const PoolDynamic =  (await this.$store.dispatch('app/getObjects', [this.pool.share['dynamic_asset_data_id']]))[0];
          const supply = PoolDynamic['current_supply'] / 10 ** this.pool.share.precision;
          let sumA = (this.pool.pool.A.balance / supply * this.unStake.amount);
          let sumB = (this.pool.pool.B.balance / supply * this.unStake.amount);
          this.unStake.A = (sumA - sumA * (this.pool.pool.withdrawal_fee_percent / 10000)).toFixed(this.pool.pool.A.precision);
          this.unStake.B = (sumB - sumB * (this.pool.pool.withdrawal_fee_percent / 10000)).toFixed(this.pool.pool.B.precision);
        }, 250);

      }
    },
    async loadUnstake(LpSymbol) {
      this.unStake.amount = null;
      this.unStake.A = 0;
      this.unStake.B = 0;
      this.pool = this.$store.getters['assets/assetsLp'][LpSymbol];
      if (this.pool) {
        await this.$store.dispatch('accounts/updateBalancesLPAssets', {
          userId: this.$root.account.account.id,
          lpId: this.pool.share.id,
          assets: [this.pool.pool.A.symbol, this.pool.pool.B.symbol]
        });

        this.balance = this.$store.getters['accounts/balancesLp'][this.pool.share.id];

      }
    }
  },
  async created() {


    if (this.$store.getters['app/fees']['62']) {
      this.chainFee = this.$store.getters['app/fees']['62'].fee / 10 ** 5;
      eventBus.on('lp:load:unstake', async (LpSymbol) => {
        this.LpSymbol = LpSymbol;
        await this.loadUnstake(this.LpSymbol);
      });

      eventBus.on('err:unstake', async () => {
        this.$snotify.clear();
        this.$snotify.error('Error Pool UnStake! Please refresh price.', {
          timeout: 4000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
        await this.lpUpdate();
      });

      eventBus.on('success:unstake', async () => {
        this.$snotify.clear();
        this.$snotify.success('Success Pool UnStake! ' + this.LpSymbol, {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
        });
        /*
        await this.$store.dispatch('accounts/updateBalances',  {
          userId: this.$root.account.account.id,
          lpId: this.pool.share.id
        });

         */

        if (this.$root.account) {
          await this.$store.dispatch('accounts/updateBalancesLPAssets', {
            userId: this.$root.account.account.id,
            lpId: this.pool.share.id,
            assets: [this.pool.pool.A.symbol, this.pool.pool.B.symbol]
          });
        }


        await this.loadUnstake(this.LpSymbol);
        await this.lpUpdate();
      });
    }

  },
  computed: {
    portfolio() {
      return this.$store.getters['accounts/portfolio'] || [];
    },
    balanceBts() {
      return this.$store.getters['accounts/portfolio']['1.3.0'].amount / 10 ** 5 || 0;
    },
  }
}
</script>

<style scoped>

</style>
