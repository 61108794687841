<!-- ОДИНОЧНЫЙ ПУЛ, ОПЕРАЦИИ -->
<template>
  <section class="xbts-defi-lp-detail">
    <div class="row">
      <div v-if="!$root.isMobile" class="col-md-6">
        <div class="card h-100">
          <p class="card-title text-center text-white mt-2">
            <b-button @click="routeTo('/pools')" class="btn btn-inverse-primary btn-rounded btn-icon btn-sm float-left ml-2">
              <i class="icon-arrow-left"></i>
            </b-button>
            <span class="font-weight-light">{{ this.$route.params.asset }} Liquidity Pool Token Provider for {{asset_a.symbol}} & {{asset_b.symbol}} assets</span>
          </p>
        <PoolInfo/>
        </div>
      </div>

      <div class="col-md-6 ml-auto mr-auto">
        <div class="card h-100">

          <div class="card-header p-1">
            <button v-show="$root['isMobile']" @click="routeTo('/pools')" type="button" class="btn btn-inverse-primary btn-sm mr-1 btn-small btn-icon">
              <i class="icon-arrow-left"></i>
            </button>

            <button @click="changeTab('trade')" type="button" class="btn btn-sm mr-1 btn-small" style="font-size: 0.75rem;"
                    :class="tab === 'trade' ? 'btn-primary' : 'btn-secondary'">{{ $t('pool.trade') }}
            </button>
            <button v-show="this.$root['account']" @click="changeTab('stake')" type="button" class="btn btn-sm mr-1" style="font-size: 0.75rem;"
                    :class="tab === 'stake' ? 'btn-primary' : 'btn-secondary'">{{ $t('pool.stake') }}
            </button>

            <button v-if="this.$root['account'] && balancesLp[pool.share_asset]" @click="unStake($route.params.asset)" type="button" class="btn btn-sm mr-1" style="font-size: 0.75rem;"
                    :class="tab === 'unstake' ? 'btn-primary' : 'btn-secondary'">{{ $t('pool.unstake') }}
            </button>
            <button v-show="$root['isMobile']" @click="changeTab('info')" type="button" class="btn btn-sm mr-1" style="font-size: 0.75rem;"
                    :class="tab === 'info' ? 'btn-primary' : 'btn-secondary'">{{ $t('pool.info') }}
            </button>

            <button @click="changeTab('stats')" type="button" class="btn btn-sm" style="font-size: 0.75rem;" :class="tab === 'stats' ? 'btn-primary' : 'btn-secondary'">{{$t('pool.stats')}}</button>


            <div v-if="$root['account']" class="float-right">
              <button v-if="$root['account']['account']['id'] === '1.2.1003215'" @click="changeTab('create')" type="button" class="btn btn-sm ml-1" style="font-size: 0.75rem;"
                      :class="tab === 'create' ? 'btn-primary' : 'btn-secondary'">+{{ $t('pool.create_pool') }}
              </button>
            </div>



          </div>

          <div v-show="tab === 'trade'" class="card-body">
            <PoolTrade/>
          </div>

          <div v-show="tab === 'stake'" class="card-body">
            <PoolStake/>
          </div>

          <div v-show="tab === 'info'" class="card-body">
            <PoolInfo/>
          </div>

          <div v-show="tab === 'unstake'" class="card-body">
            <LpUnStake/>
          </div>

          <div v-show="tab === 'stats'" class="card-body">
            <PoolStats/>
          </div>

          <div v-show="tab === 'create'" class="card-body">
            <LpCreate v-if="this.$root.account"/>
          </div>

        </div>
      </div>
    </div>
    <div class="card w-100 mt-4">
      <div class="card-body">
        <LpHistory/>
      </div>
    </div>

  </section>
</template>

<script>
import eventBus from "@/plugins/event-bus";
import PoolTrade from '@/components/lp/PoolTrade';
import PoolStake from "@/components/lp/PoolStake";
import PoolInfo from "@/components/lp/PoolInfo";
import LpUnStake from '@/components/lp/LpUnstake';
import LpHistory from '@/components/lp/LpHistory';
import PoolStats from '@/components/lp/PoolStats';
import LpCreate from '@/components/lp/LpCreate';

export default {
  name: "LpDetail",
  components: {
    PoolTrade,
    PoolStake,
    PoolInfo,
    LpUnStake,
    LpHistory,
    PoolStats,
    LpCreate,
  },
  data() {
    return {
      tab: 'trade',
      asset: {},
      pool: {},
      asset_a: {},
      asset_b: {},
    }
  },
  watch: {
    '$route.params.asset': {
      handler: async function (asset) {

        await this.getAsset(asset);

        if (this.asset_a && this.asset_b) {
          if (this.$root.account) {
            //this.asset = await this.$store.dispatch('assets/getAsset', asset);
            await this.$store.dispatch('accounts/updateBalancesLPAssets', {
              userId: this.$root.account.account.id,
              lpId: this.asset.for_liquidity_pool,
              assets: [this.asset_a.symbol, this.asset_b.symbol]
            });
          }
        }


      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    balancesLp() {
      return this.$store.getters['accounts/balancesLp'];
    }
  },
  methods: {
    async unStake(symbol) {
      this.tab = 'unstake';
      await eventBus.emit('lp:load:unstake', symbol);
    },
    async changeTab(tab) {
      this.tab = tab;
      await eventBus.emit('pool:' + tab, this.$route.params.asset);
    },
    async getAsset(asset) {
      this.asset = await this.$store.dispatch('assets/getAsset', asset);
      if (this.asset) {
        this.pool = (await this.$store.dispatch('app/getObjects', [this.asset.for_liquidity_pool]))[0];
        let assets = (await this.$store.dispatch('app/getObjects', [this.pool.asset_a, this.pool.asset_b]));
        this.asset_a = {
          symbol: assets[0].symbol,
          precision: assets[0].precision,
          balance: (this.pool.balance_a / 10 ** assets[0].precision).toFixed(assets[0].precision),
        };
        this.asset_b = {
          symbol: assets[1].symbol,
          precision: assets[1].precision,
          balance: (this.pool.balance_b / 10 ** assets[1].precision).toFixed(assets[1].precision),
        };


        await eventBus.emit('pool:trade', asset);

        if (this.pool) {
          await eventBus.emit('pool:info:load', {
            share: this.asset,
            asset_a: this.asset_a,
            asset_b: this.asset_b,
            pool: this.pool,
          });

          await eventBus.emit('lp:history', {
            poolId: this.asset.for_liquidity_pool,
            assets: {
              [this.pool.asset_a]: {
                symbol: assets[0].symbol,
                precision: assets[0].precision,
              },
              [this.pool.asset_b]: {
                symbol: assets[1].symbol,
                precision: assets[1].precision,
              },
              [this.asset.id]: {
                symbol: this.asset.symbol,
                precision: this.asset.precision,
              }
            }
          });
        }
        //await eventBus.emit('pool:stake', asset);



      }

    },
  },
  async created() {
    //await this.changeTab('trade');

    eventBus.on('pool:update', async (symbol) => {
      await this.getAsset(symbol);

      if (this.asset_a && this.asset_b) {
        if (this.$root.account) {
          //this.asset = await this.$store.dispatch('assets/getAsset', asset);
          await this.$store.dispatch('accounts/updateBalancesLPAssets', {
            userId: this.$root.account.account.id,
            lpId: this.asset.for_liquidity_pool,
            assets: [this.asset_a.symbol, this.asset_b.symbol]
          });
        }
      }
      /*
      await this.$store.dispatch('accounts/updateBalances', {
        userId: this.$root.account.account.id,
        lpId: this.asset.for_liquidity_pool
      });

       */

    });

    eventBus.on('pool:tab', async (tab) => {
      this.tab = tab;
    });
  },
}
</script>

<style scoped>

</style>
