<template>
  <section v-if="assets && history" class="xbts-lp-history">
    <table class="table table-striped table-borderless table-responsive-sm" v-if="history">
      <tbody>

      <tr class="">
        <td colspan="5" class="p-1">
          <button :class="!op ? 'btn-primary' : 'btn-secondary'" @click="setFilter(null)" class="btn btn-sm mr-1"
                  type="button">{{ $t('pool.latest') }}
          </button>
          <button :class="op === 63 ? 'btn-primary' : 'btn-secondary'" @click="setFilter(63)" class="btn btn-sm mr-1"
                  type="button">{{ $t('pool.swap') }}
          </button>
          <button :class="op === 61 ? 'btn-primary' : 'btn-secondary'" @click="setFilter(61)" class="btn btn-sm mr-1"
                  type="button">{{ $t('pool.adds') }}
          </button>
          <button :class="op === 62 ? 'btn-primary' : 'btn-secondary'" @click="setFilter(62)" class="btn btn-sm"
                  type="button">{{ $t('pool.withdrawals') }}
          </button>
        </td>
      </tr>
      <tr>
        <th>{{ $t('pool.time') }}</th>
        <th>{{ $t('pool.op') }}</th>
        <th>{{ $t('pool.paid') }}</th>
        <th>{{ $t('pool.received') }}</th>
        <th>{{ $t('market.fees') }}</th>
      </tr>
      <tr v-bind:key="item.id" v-for="item in history">
        <td>
          <span class="small">{{ formatTime(item.time) }}</span>
          <br><span class="small"># {{ item.sequence }}</span>
        </td>
        <td>
          <a :href="'http://ex.xbts.io/block/'+item.op.block_num+'/'+item.op.trx_in_block"
             target="_blank">{{ $t('pool.' + ops[item.op_type]) }}</a>
          <br><span class="small">{{ item.account }}</span>
        </td>
        <td v-if="item.result.paid">
          <p v-if="item.result.paid[0] && assets[item.result.paid[0].asset_id]">
            {{ item.result.paid[0].amount / 10 ** assets[item.result.paid[0].asset_id].precision }}
            <span class="ml-1"
                  v-html="formatAssetPrefix(assets[item.result.paid[0].asset_id].symbol.replace('XBTSX.', '')).html"></span>
          </p>
          <p v-if="item.result.paid[1]">
                        <span v-if="assets[item.result.paid[1].asset_id]">
                        {{ item.result.paid[1].amount / 10 ** assets[item.result.paid[1].asset_id].precision }}
                        <span class="ml-1"
                              v-html="formatAssetPrefix(assets[item.result.paid[1].asset_id].symbol.replace('XBTSX.', '')).html"></span>
                            </span>
          </p>
        </td>
        <td v-if="item.result.received && assets">
          <p v-if="item.result.received[0] && assets[item.result.received[0].asset_id]">
            <span v-if="assets[item.result.received[0].asset_id].precision"
                  class="text-white">{{ item.result.received[0].amount / 10 ** assets[item.result.received[0].asset_id].precision }}</span>
            <span class="ml-1"
                  v-html="formatAssetPrefix(assets[item.result.received[0].asset_id].symbol.replace('XBTSX.', '')).html"></span>
          </p>
          <p v-if="item.result.received[1] && assets">
            <span v-if="assets[item.result.received[1].asset_id].precision"
                  class="text-white">{{ item.result.received[1].amount / 10 ** assets[item.result.received[1].asset_id].precision }}</span>
            <span class="ml-1"
                  v-html="formatAssetPrefix(assets[item.result.received[1].asset_id].symbol.replace('XBTSX.', '')).html"></span>
          </p>
        </td>
        <td>
          <div v-if="item.result.fees">
                    <span class="small" v-bind:key="item.id+idx" v-for="(itemFees, idx) in item.result.fees"
                          v-show="itemFees.amount > 0">
                        <span v-if="assets[itemFees.asset_id]">
                            {{ (itemFees.amount / 10 ** assets[itemFees.asset_id].precision).toFixed(assets[itemFees.asset_id].precision) }} {{ assets[itemFees.asset_id].symbol.replace('XBTSX.', '') }}<br/>
                        </span>
                    </span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </section>

  <div v-else>
    <div class="row text-center">
      <div class="ml-auto mr-auto center-block mt-5">
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#07a882"
        />
        <br/>{{ $t('app.loading') }}..
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {
  HollowDotsSpinner
} from 'epic-spinners';

export default {
  name: "LpHistory",
  components: {
    HollowDotsSpinner,
  },
  data() {
    return {

      op: null,
      LP: [],
      ops: {
        '59': 'create',
        '60': 'delete',
        '61': 'deposit',
        '62': 'withdraw',
        '63': 'exchange',
      },
      assets: null,
      poolId: null,
      limit: 5,
    }
  },
  computed: {
    history() {
      return this.$store.getters['lp/history'];
    }
  },
  methods: {
    async getHistory() {
      try {
        await this.$store.dispatch('lp/getHistory', {
          id: this.poolId,
          op: this.op,
          limit: this.limit,
          sq: this.limit, //this.history[this.history.length - 1].sequence - this.limit,
        });
      } catch (e) {
          console.log('err getHistory()')
      }

    },
    async setFilter(op) {
      this.op = op;
      await this.getHistory();
    },
  },
  async created() {
    eventBus.on('lp:history', async (data) => {
      this.assets = data.assets;
      this.poolId = data.poolId;
      await this.getHistory();
    });
  }
}
</script>

<style scoped>

</style>
