<template>
  <section class="xbts-lp-stake">
    <div v-show="!obj">
      <div class="row text-center">
        <div class="ml-auto mr-auto center-block mt-5">
          <hollow-dots-spinner
              :animation-duration="1000"
              :dot-size="15"
              :dots-num="3"
              color="#07a882"
          />
          <br/>{{ $t('app.loading') }}..
        </div>
      </div>
    </div>

    <div v-if="obj">

      <div v-show="step === 1">
        <div class="my-1" v-if="balance">
          <div v-if="$root.account" @click="stakeMax('a')" class="float-right small text-right">
            {{ $t('wallet.available') }}
            <span class="text-primary cursor-pointer"
                  v-if="balance[stake.a.id]">{{ (balance[stake.a.id].amount / 10 ** balance[stake.a.id].precision).toFixed(balance[stake.a.id].precision) }} </span><span
              v-html="formatAssetPrefix(stake.a.symbol).html"></span>
          </div>
          <!--
          <p>
            <img v-if="stake.a.symbol" :src="'images/assets/' + stake.a.symbol.toLowerCase() + '.png'" class="mr-1 icon-18"/>
          </p>
          -->


          <b-form-group
              classs="p-0"
              label-for="input_sell">
            <b-input-group

            :append="stake.a.symbol.replace('XBTSX.', '')">
              <b-form-input @change="enterAmountStake('a')" @input="enterAmountStake('a')"
                            id="input_sell"
                            placeholder=""
                            type="text" v-model="stake.a.amount"></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="text-center">
          <!--{{stake.a.symbol.replace('XBTSX.', '')}}--> <img v-if="stake.a.symbol" :src="'images/assets/' + stake.a.symbol.toLowerCase() + '.png'" class="mr-1 icon-24 mb-2"/> <span style="font-size:20px;">+</span><!--{{stake.b.symbol.replace('XBTSX.', '')}}--> <img v-if="stake.b.symbol" :src="'images/assets/' + stake.b.symbol.toLowerCase() + '.png'" class="ml-1 icon-24 mb-2"/>
        </div>

        <div class="my-0" v-if="balance">
          <div v-if="$root.account" @click="stakeMax('b')" class="float-right small text-right">
            {{ $t('wallet.available') }} <span
              class="text-primary cursor-pointer"
              v-if="balance[stake.b.id]">{{ (balance[stake.b.id].amount / 10 ** balance[stake.b.id].precision).toFixed(balance[stake.b.id].precision) }}</span>
            <span v-html="formatAssetPrefix(stake.b.symbol).html"></span>
          </div>
          <!--:label="$t('assets.asset') + ' B'"-->
          <b-form-group
              label-for="input_receive">
            <b-input-group :append="stake.b.symbol.replace('XBTSX.', '')">
              <b-form-input @input="enterAmountStake('b')" id="input_receive" placeholder=""
                            type="text"
                            v-model="stake.b.amount"></b-form-input>
            </b-input-group>
          </b-form-group>
          <div class="row">
            <div class="col-md-6 small text-left">

            </div>

          </div>
        </div>

        <table class="table">
          <tbody>
          <tr>
            <td>{{ $t('market.price') }}</td>
            <td class="text-right">
              <span class="text-info mr-1">{{ stake.b.price }}</span>
              <span v-html="formatAssetPrefix(stake.b.symbol.replace('XBTSX.', '')).html"></span> / <span
                v-html="formatAssetPrefix(stake.a.symbol.replace('XBTSX.', '')).html"></span>
            </td>
          </tr>
          <tr>
            <td>{{ $t('pool.reverse_price') }}</td>
            <td class="text-right"><span class="text-info mr-1">{{ stake.a.price }}</span>
              <span v-html="formatAssetPrefix(stake.a.symbol.replace('XBTSX.', '')).html"></span> / <span
                  v-html="formatAssetPrefix(stake.b.symbol.replace('XBTSX.', '')).html"></span>

            </td>
          </tr>
          <tr>
            <td>{{ $t('assets.supply') }}</td>
            <td class="text-right"><span class="text-info">{{ stake.share.supply }}</span>
              <span class="ml-1" v-html="formatAssetPrefix(stake.share.symbol).html"></span>
            </td>
          </tr>
          <tr>
            <td>{{ $t('pool.estimated') }}</td>
            <td class="text-right">
                      <span class="text-success">
                        <animated-number
                            :value="stake.share.amount"
                            :formatValue="formatToPrice"
                            :duration="300"
                        />&nbsp;({{ stake.share.percent }}%)
                      </span>
              <span class="ml-1" v-html="formatAssetPrefix(stake.share.symbol).html"></span>
            </td>
          </tr>
          <tr>
            <td>{{ $t('wallet.fee_network') }}</td>
            <td class="text-right">{{ chainFee }} BTS
            </td>
          </tr>
          </tbody>
        </table>


        <b-alert v-show="chainFee > balanceBts && this.$root.account" variant="warning" show
                 class="mt-2 bg-gradient- bg-warning text-dark small">
          No BTS balance for pay fees
        </b-alert>

        <button
            :disabled="chainFee > balanceBts || (stake.a.balance < stake.a.amount || stake.b.balance < stake.b.amount) || (!stake.a.amount || !stake.b.amount)"
            @click="confirmStake"
            class="btn btn-inverse-warning btn-fw w-100 text-uppercase"
            type="button" v-if="this.$root.account">{{ $t('pool.add_to_stake') }}
        </button>


      </div>

      <div v-show="step === 2">
        <div v-if="result">
          <p class="text-success">
            STAKE DEPOSIT SUCCESS
          </p>
        </div>
        <div class="row" v-if="!result">
          <div class="ml-auto mr-auto">
            <intersecting-circles-spinner
                :animation-duration="1200"
                :size="70"
                color="#f9a901"
            />
          </div>
        </div>


        <button @click="step = 1" class="btn btn-inverse-primary btn-fw w-100 mt-4" type="button">
          {{ $t('app.continue') }}
        </button>


      </div>
    </div>

    <button @click="routeTo('/auth-pages/login')" v-if="!$root.account"
            class="btn btn-inverse-primary btn-fw w-100 mt-5 text-uppercase" type="button">{{ $t('app.please_login') }}
    </button>
  </section>
</template>

<script>
import {IntersectingCirclesSpinner, HollowDotsSpinner} from 'epic-spinners'
import eventBus from '@/plugins/event-bus';
import AnimatedNumber from "animated-number-vue";

export default {
  name: "PoolStake",
  components: {
    IntersectingCirclesSpinner,
    HollowDotsSpinner,
    AnimatedNumber,
  },
  data() {
    return {
      //chainFee: 0,
      timerBalance: null,
      timerStake: null,
      obj: null,
      step: 1,
      stake: {
        a: {
          symbol: "",
          amount: null,
          price: 0,
          id: null,
          balance: 0,
          precision: 0,
        },
        b: {
          symbol: "",
          amount: null,
          price: 0,
          id: null,
          balance: 0,
          precision: 0,
        },
        share: {
          symbol: "",
          amount: 0,
          id: null,
          precision: 0,
          supply: 0,
          dyn_data: {},
          balanceA: 0,
          balanceB: 0,
          percent: 0,
        }
      },
      account: {},
      balances: {},
      raw: null,
      result: null,
    }
  },
  async created() {
    //this.chainFee = this.$store.getters['app/fees']['61'].fee / 10 ** 5;
    eventBus.on('pool:stake', async (symbol) => {
      this.step = 1;
      this.result = null;
      await this.setPoolData(symbol);
    });

    eventBus.on('err:stake', async () => {
      await this.notifyError(this.$t('NOTIFY.stake_error'));
    });

    eventBus.on('success:stake', async () => {
      await this.notifySuccess(this.$t('NOTIFY.stake_success') + ' ' + this.obj.asset.symbol);
    });
  },
  methods: {
    formatToPrice(value) {
      return `${value.toFixed(this.stake.share.precision)}`;
    },
    async floatRound(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    },
    async truncFloat(num, decimalPlaces) {
      let numPowerConverter = Math.pow(10, decimalPlaces);
      return ~~(num * numPowerConverter) / numPowerConverter;
    },
    async lpUpdate() {
      await eventBus.emit('pool:update', this.obj.asset.symbol);
    },
    async confirmStake() {
      clearTimeout(this.timerStake)
      this.result = null;
      this.step = 2;
      // op 61
      this.raw = {
        "fee": {
          "amount": this.$store.getters['app/fees']['61'].fee,
          "asset_id": "1.3.0"
        },
        "account": this.$root.account.account.id,
        "pool": this.obj.pool.id,
        "amount_a": {
          "amount": this.stake.a.amount * 10 ** this.stake.a.precision,
          "asset_id": this.stake.a.id
        },
        "amount_b": {
          "amount": this.stake.b.amount * 10 ** this.stake.b.precision,
          "asset_id": this.stake.b.id
        },
        "extensions": []
      };


      this.result = await this.$store.dispatch('lp/LpStake', {
        account: this.$root.account,
        operation: this.raw,
      });

      if (this.result) {
        this.raw = null;
        this.result = null;
        this.step = 1;
      }

      clearTimeout(this.timerStake);
      this.timerStake = setTimeout(async () => {
        await this.lpUpdate();
        if (this.obj) {
          await this.setStake(this.obj);
        }

      }, 3200);

    },
    async setPoolData(symbol) {
      const tmpAsset = await this.$store.dispatch('assets/getAsset', symbol);
      const pool = (await this.$store.dispatch('app/getObjects', [tmpAsset.for_liquidity_pool]))[0]
      this.obj = {
        asset: tmpAsset,
        dyn_data: (await this.$store.dispatch('app/getObjects', [tmpAsset.dynamic_asset_data_id]))[0],
        pool: pool,
        poolA: (await this.$store.dispatch('app/getObjects', [pool.asset_a]))[0],
        poolB: (await this.$store.dispatch('app/getObjects', [pool.asset_b]))[0],
      };
      await this.setStake(this.obj);
    },
    async setStake(obj) {

      //await this.accountBalances();

      this.stake.share.symbol = obj.asset.symbol; // fix it
      this.stake.share.precision = obj.asset.precision;
      this.stake.share.dyn_data = (await this.$store.dispatch('app/getObjects', [obj.asset.dynamic_asset_data_id]))[0];
      this.stake.share.supply = this.stake.share.dyn_data.current_supply ? this.stake.share.dyn_data.current_supply / 10 ** this.stake.share.precision : 0;
      this.stake.share.balanceA = obj.pool.balance_a ? obj.pool.balance_a / 10 ** obj.poolA.precision : 0;
      this.stake.share.balanceB = obj.pool.balance_b ? obj.pool.balance_b / 10 ** obj.poolB.precision : 0;

      this.stake.a.symbol = obj.poolA.symbol;
      this.stake.a.id = obj.poolA.id;
      this.stake.a.precision = obj.poolA.precision;
      this.stake.a.balance = this.balance[obj.poolA.id] ? this.balance[obj.poolA.id].amount / 10 ** obj.poolA.precision : 0;
      //this.stake.a.price = obj.pool.balance_a ? (((obj.pool.balance_a / 10 ** obj.poolA.precision) / (obj.pool.balance_b / 10 ** obj.poolB.precision)).toFixed(obj.poolA.precision)) : 0;

      if (obj.pool.balance_a) {
        this.stake.a.price = (((obj.pool.balance_a / 10 ** obj.poolA.precision) / (obj.pool.balance_b / 10 ** obj.poolB.precision)).toFixed(obj.poolA.precision)) * 1;
        if (this.stake.a.price === 0) {
          this.stake.a.price = (((obj.pool.balance_a / 10 ** obj.poolA.precision) / (obj.pool.balance_b / 10 ** obj.poolB.precision)).toFixed(14));
        }
      }

      this.stake.b.symbol = obj.poolB.symbol;
      this.stake.b.id = obj.poolB.id;
      this.stake.b.precision = obj.poolB.precision;
      this.stake.b.balance = this.balance[obj.poolB.id] ? this.balance[obj.poolB.id].amount / 10 ** obj.poolB.precision : 0;
      this.stake.b.price = obj.pool.balance_b ? (((obj.pool.balance_b / 10 ** obj.poolB.precision) / (obj.pool.balance_a / 10 ** obj.poolA.precision)).toFixed(obj.poolB.precision)) : 0;

      clearTimeout(this.timerBalance);
      if (this.$root.account) {
        this.timerBalance = setTimeout(async () => {
          await this.$store.dispatch('accounts/updateBalances', {
            userId: this.$root.account.account.id,
            lpId: this.obj.pool.id
          });
        }, 5000);
      }

    },
    async stakeMax(asset = 'a') {
      if (asset === 'a') {
        this.stake.a.amount = (this.balance[this.stake.a.id].amount / 10 ** this.balance[this.stake.a.id].precision).toFixed(this.balance[this.stake.a.id].precision) * 1;
        if (this.stake.a.amount * 1 === 0) {
          this.stake.a.amount = (this.balance[this.stake.a.id].amount / 10 ** this.balance[this.stake.a.id].precision).toFixed(14) * 1;
        }
      }
      if (asset === 'b') {
        this.stake.b.amount = (this.balance[this.stake.b.id].amount / 10 ** this.balance[this.stake.b.id].precision).toFixed(this.balance[this.stake.b.id].precision) * 1;
        if (this.stake.b.amount * 1 === 0) {
          this.stake.b.amount = (this.balance[this.stake.b.id].amount / 10 ** this.balance[this.stake.b.id].precision).toFixed(14) * 1;
        }
      }
      await this.enterAmountStake(asset);
    },
    async enterAmountStake(asset = 'a') {
      if (this.stake.share.supply) {
        if (asset === 'a') {
          this.stake.a.amount ? this.stake.b.amount = (this.stake.a.amount * this.stake.b.price).toFixed(this.stake.b.precision) : this.stake.b.amount = null;
          this.stake.share.amount = (this.stake.share.supply * this.stake.a.amount / this.stake.share.balanceA).toFixed(this.stake.share.precision);
        }
        if (asset === 'b') {
          this.stake.b.amount ? this.stake.a.amount = (this.stake.b.amount * this.stake.a.price).toFixed(this.stake.a.precision) : this.stake.a.amount = null;
          this.stake.share.amount = (this.stake.share.supply * this.stake.b.amount / this.stake.share.balanceB).toFixed(this.stake.share.precision);
        }

        this.stake.share.percent = Math.floor((this.stake.share.amount / (this.stake.share.supply * 1 + this.stake.share.amount * 1) * 100) * 100) / 100;
      } else {

      }
    },

  },
  computed: {
    balance() {
      return this.$store.getters['accounts/portfolio'] || 0;
    },
    chainFee() {
      return this.$store.getters['app/fees']['63'].fee / 10 ** 5
    },
    balanceBts() {
      if (this.$store.getters['accounts/portfolio']['1.3.0']) {
        return this.$store.getters['accounts/portfolio']['1.3.0'].amount / 10 ** 5 || 0;
      } else {
        return 0;
      }
    },
  }
}
</script>

<style scoped>

</style>
