<template>
  <section class="xbts-lp-stats">
    <div>
      <table v-if="current" class="table text-white">
        <tbody>

        <tr>
          <td></td>
          <td class="text-left"><span
              class="card-title text-left text-warning">{{ $t('pool.24h') }}</span></td>
          <td class="text-warning">{{ $t('pool.30d') }}</td>
          <td class="text-warning">{{ $t('pool.1y') }}</td>
        </tr>
        <tr>
          <td class="text-capitalize">{{ $t('pool.total_ops') }}</td>
          <td>
            <span v-if="current.POOL">
              {{current.POOL.statistics._24h_deposit_count + current.POOL.statistics._24h_withdrawal_count + current.POOL.statistics._24h_exchange_a2b_count + current.POOL.statistics._24h_exchange_b2a_count}}
            </span>
          </td>
          <td>
            <span v-if="current.POOL && current.A.vol24 > 0">
              {{(current.POOL.statistics._24h_deposit_count + current.POOL.statistics._24h_withdrawal_count + current.POOL.statistics._24h_exchange_a2b_count + current.POOL.statistics._24h_exchange_b2a_count)*30}}
            </span>
          </td>
          <td>
            <span v-if="current.POOL && current.A.vol24 > 0">
              {{(current.POOL.statistics._24h_deposit_count + current.POOL.statistics._24h_withdrawal_count + current.POOL.statistics._24h_exchange_a2b_count + current.POOL.statistics._24h_exchange_b2a_count)*365}}
            </span>
          </td>
        </tr>

        <tr>
          <td class="text-capitalize">
            <div v-if="current.A">
            <span v-if="allAssets[current.A.asset.symbol]"><img :src="'images/assets/' + current.A.asset.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/></span>
            <span v-if="!allAssets[current.A.asset.symbol]"><img :src="'images/assets/coin.png'" class="mr-2 icon-18"/></span>
            {{$t('pool.vol')}} {{ current.A.asset.symbol.replace('XBTSX.', '') }}
            </div>
          </td>
          <td>
            <span v-if="current.A">
                {{ numTransform(current.A.vol24)}}
              </span>
          </td>
          <td>
            <span v-if="current.A && current.A.vol24 > 0">
                {{ numTransform((current.A.vol24 * 30).toFixed(current.A.asset.precision))}}
              </span>
          </td>
          <td>
            <span v-if="current.A && current.A.vol24 > 0">
                {{ numTransform((current.A.vol24 * 365).toFixed(current.A.asset.precision))}}
              </span>
          </td>
        </tr>

        <tr>
          <td class="text-capitalize">
            <div v-if="current.B">
            <span v-if="allAssets[current.B.asset.symbol]"><img :src="'images/assets/' + current.B.asset.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/></span>
            <span v-if="!allAssets[current.B.asset.symbol]"><img :src="'images/assets/coin.png'" class="mr-2 icon-18"/></span>
            {{$t('pool.vol')}} {{ current.B.asset.symbol.replace('XBTSX.', '') }}
            </div>
          </td>
          <td>
            <div v-if="current.B">
            {{ numTransform(current.B.vol24)}}
            </div>
          </td>
          <td>
            <div v-if="current.B && current.B.vol24 > 0">
              {{ numTransform((current.B.vol24 * 30).toFixed(current.B.asset.precision))}}
            </div>
          </td>
          <td>
            <div v-if="current.B && current.B.vol24 > 0">
              {{ numTransform((current.B.vol24 * 365).toFixed(current.B.asset.precision))}}
            </div>
          </td>
        </tr>


        <tr>
          <td class="text-capitalize">
            <div v-if="current.A">
            <span v-if="allAssets[current.A.asset.symbol]"><img :src="'images/assets/' + current.A.asset.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/></span>
            <span v-if="!allAssets[current.A.asset.symbol]"><img :src="'images/assets/coin.png'" class="mr-2 icon-18"/></span>
            {{ $t('market.fees') }} {{ current.A.asset.symbol.replace('XBTSX.', '') }}
            </div>
          </td>
          <td>
            <div v-if="current.A">
            {{ current.A.fee24}} {{ current.A.asset.symbol.replace('XBTSX.', '') }}
            </div>
          </td>
          <td>
            <div v-if="current.A && current.A.fee24 > 0">
              {{ numTransform((current.A.fee24 * 30).toFixed(current.A.asset.precision))}}
            </div>
          </td>
          <td>
            <div v-if="current.A && current.A.fee24 > 0">
              {{ numTransform((current.A.fee24 * 365).toFixed(current.A.asset.precision))}}
            </div>
          </td>
        </tr>
        <tr>
          <td class="text-capitalize">
            <div v-if="current.B">
            <span v-if="allAssets[current.B.asset.symbol]"><img :src="'images/assets/' + current.B.asset.symbol.toLowerCase() + '.png'" class="mr-2 icon-18"/></span>
            <span v-if="!allAssets[current.B.asset.symbol]"><img :src="'images/assets/coin.png'" class="mr-2 icon-18"/></span>
            {{ $t('market.fees') }} {{ current.B.asset.symbol.replace('XBTSX.', '') }}
            </div>
          </td>
          <td>
            <div v-if="current.B">
            {{ current.B.fee24}}
            </div>
          </td>
          <td>
            <div v-if="current.B && current.B.fee24 > 0">
              {{ numTransform((current.B.fee24 * 30).toFixed(current.B.asset.precision))}}
            </div>
          </td>
          <td>
            <div v-if="current.B && current.B.fee24 > 0">
              {{ numTransform((current.B.fee24 * 365).toFixed(current.B.asset.precision))}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <p class="small mt-2 text-center">{{$t('pool.stat_info')}}</p>
    </div>
  </section>
</template>

<script>
import {xbtsAssets, nativeAssets, xbtsFiat, otherAssets} from "@/config";
export default {
  name: "PoolStats",
  data() {
    return {
      allAssets: {},
    }
  },
  computed: {
    current() {
      return this.$store.getters['lp/current'];
    }
  },
  methods: {

  },
  async created() {
    const assets = xbtsAssets.concat(nativeAssets, xbtsFiat, otherAssets);
    for (let i = 0; i < assets.length; i++) {
      this.allAssets[assets[i]] = true;
    }
  }
}
</script>

<style scoped>

</style>
