<template>
  <section v-if="isLoad" class="xbts-lp-trade h-100">
    <div>
      <div v-show="step === 1">
        <div class="my-1" v-show="exchange.sell.symbol">
          <div @click="sellMax" v-if="this.$root.account" class="float-right small text-right">
            {{ $t('wallet.available') }} <span
              v-if="balance[exchange.sell.id]"
              class="text-primary cursor-pointer">
                  <!--
                  <animated-number
                      :value="(balance[exchange.sell.id].amount / 10 ** balance[exchange.sell.id].precision)"
                      :formatValue="formatToPrice"
                      :duration="300"
                  />
                  -->
                  {{
              (balance[exchange.sell.id].amount / 10 ** balance[exchange.sell.id].precision).toFixed(balance[exchange.sell.id].precision)
            }}
                </span> <span v-html="formatAssetPrefix(exchange.sell.symbol).html"></span>
          </div>
          <b-form-group
              :label="$t('pool.to_sell')"
              label-for="input_sell">
            <b-input-group :append="exchange.sell.symbol.replace('XBTSX.', '')">
              <b-form-input @change="enterAmountSell" @input="enterAmountSell" id="input_sell"
                            :placeholder="$t('wallet.amount') + (exchange.sell.symbol === 'XBTSX.BTC' ? ' min 0.0001000' : '')"
                            type="text" v-model="models.amount.sell"></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="row">
          <div class="col-md-6 small text-left">
            {{ $t('market.price') }}
            <span class="text-info mr-1">
                    {{ (exchange.sell.price * 1).toFixed(exchange.sell.precision) }}
                  </span>
            <span v-html="formatAssetPrefix(exchange.sell.symbol.replace('XBTSX.','')).html"></span>/<span
              v-html="formatAssetPrefix(exchange.receive.symbol.replace('XBTSX.','')).html"></span>
          </div>
          <!--
          <div class="col-md-6 small text-right">
            Pool reserve {{exchange.sell.poolBalance}} {{exchange.sell.symbol.replace('XBTSX.','')}}
           </div>
           -->

        </div>

        <hr/>

        <p class="text-center">
          <b-button @click="exchangeInverse" class="btn btn-inverse-primary btn-rounded btn-icon">
            <i class="icon-refresh"></i>
          </b-button>
        </p>

        <hr/>

        <div class="my-4" v-if="obj.pool">
          <b-form-group
              :label="$t('pool.min_receive')"
              label-for="input_receive">
            <b-input-group :append="exchange.receive.symbol.replace('XBTSX.', '')">
              <b-form-input readonly @input="enterAmountReceive" id="input_receive" placeholder=""
                            type="text"
                            v-model="models.amount.receive"></b-form-input>
            </b-input-group>
          </b-form-group>
          <div class="row">
            <div class="col-md-6 small text-left">
              {{ $t('market.price') }} <span
                class="text-info mr-1">{{ (exchange.receive.price * 1).toFixed(exchange.receive.precision) }}</span>
              <span v-html="formatAssetPrefix(exchange.receive.symbol.replace('XBTSX.','')).html"></span>/<span
                v-html="formatAssetPrefix(exchange.sell.symbol.replace('XBTSX.','')).html"></span>
            </div>
            <!--
            <div class="col-md-6 small text-right">
              Pool reserve {{exchange.receive.poolBalance}} {{exchange.receive.symbol.replace('XBTSX.','')}}
            </div>
            -->
          </div>

          <div class="row">
            <div class="col-md-12">
              <!--<span class="badge badge-info m-1">Fees {{exchange.receive.fee.toFixed(exchange.receive.precision)}}%</span>-->
              <span class="badge badge-primary m-1">Taker {{ (obj.pool.taker_fee_percent / 100).toFixed(2) }}%</span>
              <span class="badge badge-primary m-1">Market {{ (exchange.receive.marketFee / 100).toFixed(2) }}%</span>
              <span class="badge badge-info m-1">{{ $t('wallet.fee_network') }} {{ chainFee }} BTS</span>
              <span v-if="exchange.priceDiff"
                    class="badge badge-dark m-1">{{ $t('pool.price_diff') }} {{ exchange.priceDiff }}%</span>
            </div>
          </div>


          <b-alert v-show="chainFee > balanceBts && this.$root.account" variant="warning" show
                   class="mt-2 bg-gradient- bg-warning text-dark small">
            No BTS balance for pay fees
          </b-alert>

          <button v-if="this.$root.account"
                  :disabled="models.amount.sell > exchange.sell.balance || chainFee > balanceBts"
                  @click="ConfirmExchange"
                  class="btn btn-inverse-warning btn-fw w-100 mt-5 text-uppercase" type="button">{{ $t('pool.trade') }}
          </button>


        </div>
      </div>

      <div v-show="step === 2">
        <div class="row" v-if="!result">
          <div class="ml-auto mr-auto">
            <intersecting-circles-spinner
                :animation-duration="1200"
                :size="70"
                color="#f9a901"
            />
          </div>
        </div>
        <button @click="step = 1"
                class="btn btn-inverse-primary btn-fw w-100 mt-5" type="button">{{ $t('app.continue') }}
        </button>
      </div>

      <button @click="routeTo('/auth-pages/login')" v-if="!$root.account"
              class="btn btn-inverse-primary btn-fw w-100 mt-5 text-uppercase" type="button">
        {{ $t('app.please_login') }}
      </button>

    </div>
  </section>
  <div v-else>
    <div class="row text-center">
      <div class="ml-auto mr-auto center-block mt-5">
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="3"
            color="#07a882"
        />
        <br/>{{ $t('app.loading') }}..
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import {
  IntersectingCirclesSpinner,
  HollowDotsSpinner
} from 'epic-spinners';
//import AnimatedNumber from "animated-number-vue";

export default {
  name: "LpTrade",
  components: {
    IntersectingCirclesSpinner,
    HollowDotsSpinner,
    //AnimatedNumber,
  },
  data() {
    return {
      //chainFee: 0,
      raw: null,
      step: 1,
      asset: null,
      obj: {},
      models: {
        amount: {
          sell: null,
          receive: null
        }
      },
      exchange: {
        fee: 0,
        precision: 0,
        priceDiff: 0,
        share: {},
        sell: {
          symbol: "",
          amount: 0,
          price: 0,
          id: null,
          balance: 0,
          precision: 0,
          fee: 0,
          marketFee: 0,
        },
        receive: {
          symbol: "",
          amount: 0,
          price: 0,
          id: null,
          balance: 0,
          precision: 0,
          fee: 0,
          marketFee: 0,
        }
      },
      account: {},
      balances: {},
      result: null,
      isLoad: false,
    }
  },
  mounted() {

  },
  async created() {
    eventBus.on('pool:trade', async (symbol) => {
      this.isLoad = await this.setPoolData(symbol);
    });

    eventBus.on('err:exchange', async () => {
      await this.notifyError(this.$t('NOTIFY.exchange_error'));
    });

    eventBus.on('success:exchange', async () => {
      await this.notifySuccess(this.$t('NOTIFY.exchange_success') + ' ' + this.obj.asset.symbol);
      await this.setExchange(this.obj);
      await this.lpUpdate();
    });
  },
  methods: {
    formatToPrice(value) {
      return `${value.toFixed(this.balance[this.exchange.sell.id].precision)}`;
    },
    async lpUpdate() {
      await eventBus.emit('pool:update', this.obj.asset.symbol);
    },
    async setPoolData(symbol) {
      const tmpAsset = await this.$store.dispatch('assets/getAsset', symbol);
      const pool = (await this.$store.dispatch('app/getObjects', [tmpAsset.for_liquidity_pool]))[0]
      this.obj = {
        asset: tmpAsset,
        dyn_data: (await this.$store.dispatch('app/getObjects', [tmpAsset.dynamic_asset_data_id]))[0],
        pool: pool,
        poolA: (await this.$store.dispatch('app/getObjects', [pool.asset_a]))[0],
        poolB: (await this.$store.dispatch('app/getObjects', [pool.asset_b]))[0],
      };
      await this.setExchange(this.obj);
      return true;
    },
    async ConfirmExchange() {
      // op 63
      let minToReceive = Math.trunc(this.models.amount.receive * 10 ** this.exchange.receive.precision);
      //minToReceive = 160 // сумма должна быть меньше к получению
      this.raw = {
        "fee": {
          "amount": this.$store.getters['app/fees']['63'].fee,
          "asset_id": "1.3.0"
        },
        "account": this.$root.account.account.id,
        "pool": this.obj.pool.id,
        "amount_to_sell": {
          "amount": this.models.amount.sell * 10 ** this.exchange.sell.precision,
          "asset_id": this.exchange.sell.id
        },
        "min_to_receive": {"amount": minToReceive, "asset_id": this.exchange.receive.id}
      };
      this.step = 2;

      this.result = await this.$store.dispatch('lp/LpExchange', {
        account: this.$root.account,
        operation: this.raw,
      });
      this.step = 1;
    },
    async enterAmountReceive() {
      this.models.amount.receive ? this.models.amount.sell = (this.models.amount.receive * this.exchange.sell.price).toFixed(this.exchange.precision) : this.models.amount.sell = null;
    },
    async sellMax() {
      this.models.amount.sell = this.exchange.sell.balance;
      await this.enterAmountSell();
    },
    async enterAmountSell() {
      if (this.models.amount.sell) {
        //let balanceA = (this.obj.pool.balance_a / 10 ** this.obj.poolA.precision);
        //let balanceB = (this.obj.pool.balance_b / 10 ** this.obj.poolB.precision);
        //let priceStart = (balanceB / balanceA).toFixed(this.exchange.receive.precision) * 1;
        let expectedBalanceSell = this.exchange.sell.poolBalance + this.models.amount.sell * 1;
        let price = (this.exchange.receive.poolBalance / expectedBalanceSell).toFixed(this.exchange.receive.precision) * 1;

        this.exchange.receive.price = price;
        const percentTakerMarket = (this.obj.pool.taker_fee_percent * 1 + this.exchange.receive.marketFee * 1) / 10000

        let expectedBalanceReceive = this.exchange.receive.poolBalance - this.models.amount.sell * price;
        let expectedPrice = (expectedBalanceReceive / expectedBalanceSell);
        this.exchange.priceDiff = (100 - (expectedPrice / price * 100)).toFixed(6);
        const totalFeePercent = (2 / 100 + percentTakerMarket + this.exchange.priceDiff / 100); // сумма всех процентов
        //let priceFee = (price * ((this.obj.pool.taker_fee_percent * 1 + this.exchange.receive.marketFee * 1) / 10000));
        //this.models.amount.receive = (this.models.amount.sell * (price - priceFee)).toFixed(this.exchange.receive.precision);
        this.exchange.receive.fee = totalFeePercent;


        //this.models.amount.receive = (this.models.amount.receive - (this.models.amount.receive * 0.02)).toFixed(this.exchange.receive.precision);


        this.models.amount.receive = (this.models.amount.sell * price - ((this.models.amount.sell * price) * totalFeePercent)).toFixed(this.exchange.receive.precision);
        // сумма к получению = суммма_к_получению - сумма_к_получению * (1% + %смещения цены)

        //this.exchange.priceDiff = (100 - (priceStart / price * 100)).toFixed(4);
        //this.exchange.share.amount = this.exchange.share.supply - this.models.amount.receive * this.models.amount.sell;
      } else {
        this.models.amount.receive = null
      }
    },
    async exchangeInverse() {
      let tmpSell = this.exchange.sell;
      let tmpReceive = this.exchange.receive;
      this.exchange.receive = tmpSell;
      this.exchange.sell = tmpReceive;
      this.models.amount.sell = null;
      this.models.amount.receive = null;
    },
    /*
    async accountBalances() {
        if (this.$root.account) {
            this.account = (await this.$store.dispatch('accounts/fullAccounts', [this.$root.account.account.id]))[0][1];
            if (this.account) {
                for (let i = 0; i < this.account.balances.length; i++) {
                    if (this.account.balances[i].balance > 0) {
                        this.balances[this.account.balances[i].asset_type] = this.account.balances[i].balance
                    }
                }
            }
        }
        return this.balances;
    },
     */
    async setExchange(obj) {
      this.models.amount.sell = null;
      this.models.amount.receive = null;
      //await this.accountBalances();
      const fixedPrecision = obj.poolA.precision > obj.poolB.precision ? obj.poolA.precision : obj.poolB.precision;
      this.exchange.precision = fixedPrecision;
      this.exchange.fee = obj.pool.taker_fee_percent;

      const priceA = ((obj.pool.balance_a / 10 ** obj.poolA.precision) / (obj.pool.balance_b / 10 ** obj.poolB.precision)).toFixed(fixedPrecision);
      this.exchange.sell.symbol = obj.poolA.symbol;
      this.exchange.sell.price = priceA;
      this.exchange.sell.id = obj.poolA.id;
      this.exchange.sell.balance = this.balance[obj.poolA.id] ? this.balance[obj.poolA.id].amount / 10 ** obj.poolA.precision : 0;
      this.exchange.sell.precision = obj.poolA.precision;
      this.exchange.sell.marketFee = obj.poolA.options.market_fee_percent;
      this.exchange.sell.poolBalance = (this.obj.pool.balance_a / 10 ** this.obj.poolA.precision);

      let priceB = ((obj.pool.balance_b / 10 ** obj.poolB.precision) / (obj.pool.balance_a / 10 ** obj.poolA.precision)).toFixed(fixedPrecision);
      this.exchange.receive.symbol = obj.poolB.symbol;
      this.exchange.receive.price = priceB;
      this.exchange.receive.id = obj.poolB.id;
      this.exchange.receive.balance = this.balance[obj.poolB.id] ? this.balance[obj.poolB.id].amount / 10 ** obj.poolB.precision : 0;
      this.exchange.receive.precision = obj.poolB.precision;
      this.exchange.receive.marketFee = obj.poolB.options.market_fee_percent;
      this.exchange.receive.poolBalance = (this.obj.pool.balance_b / 10 ** this.obj.poolB.precision);

      this.exchange.share.supply = this.obj.dyn_data.current_supply / 10 ** this.obj.asset.precision;
    }
  },
  computed: {
    balance() {
      return this.$store.getters['accounts/portfolio'] || [];
    },
    chainFee() {
      return this.$store.getters['app/fees']['63'].fee / 10 ** 5;
    },
    balanceBts() {
      if (this.$store.getters['accounts/portfolio']['1.3.0']) {
        return this.$store.getters['accounts/portfolio']['1.3.0'].amount / 10 ** 5 || 0;
      } else {
        return 0;
      }
    },
  }
}
</script>

<style scoped>

</style>
